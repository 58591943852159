@font-face {
    font-family: 'NunitoSans';
    src: url('../../../../public/media/fonts/NunitoSans10pt-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: 'NunitoSans';
    src: url('../../../../public/media/fonts/NunitoSans10pt-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
  }
  
  // Import other styles
  @import "init";

  
  // Components
  @import "./core/components/components";
  @import "components/components";
  
  // Layout
  @import "./core/layout/base/layout";
  @import "layout/layout";
  